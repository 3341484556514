import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import FmcgContentList from '../components/FmcgContentList';
import FmcgSolution from '../components/FmcgSolution';
import CustomerCase from '../components/CustomerCase';
import HomeFeatures from '../components/HomeFeatures';
import VerticalBox from '../components/VerticalBox';
import IconText from '../components/IconText';
import Budweiser from '../images/budweiser.svg';

function CustomIconText({ title, icon, content }) {
  return (
    <Col xl={6} sm={12} className="icon-text-col">
      <IconText title={title} content={content} icon={icon} iconPosition="top" />
    </Col>
  );
}

CustomIconText.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.string,
};

export default () => (
  <Layout current="solutions" subCurrent="fmcg">
    <CommonPageBanner
      title="快消行业解决方案"
      description="快消行业面对新零售时代的人-货-场重构，搭建以数字化驱动的完整营销体系，开启全渠道消费者增长的商业模式。"
      imgName="fmcg"
    />
    <TitledVerticalBoxes title="快消行业的全新运营挑战">
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={15}
          title="精准用户画像"
          description="快消品牌普遍期望加强对自身顾客群体的全面认知"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={16}
          title="D2C运营"
          description="受新零售潮流和疫情等客观因素影响，直连消费者能力受到重视"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={17}
          title="会员运营体系"
          description="对分散在电商平台、小程序、自媒体和线下的会员进行统一运营"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={18}
          title="全渠道整合"
          description="完整对接OMS、CRM、EC电商平台、门店、POS机等全营销渠道"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={19}
          title="品效合一"
          description="品牌宣传和营销活动对客群的触达效果都成为营销人员的考核目标"
        />
      </Col>
      <Col lg={8} md={12} xs={12}>
        <VerticalBox
          iconIndex={20}
          title="个性化运营"
          description="消费者开始反感千篇一律的产品和营销推送，个性化营销成为必须"
        />
      </Col>
    </TitledVerticalBoxes>
    <HomeFeatures
      title="CDP+MA的数字化营销体系"
      // subTitle="Convertlab帮助快消品牌搭建的体系"
      paddingTop={0}
      backgroundRevert
    />
    <TitledVerticalBoxes title="搭建完善的会员运营体系" shadowed sectionId="fmcg-2">
      <Col className="icon-text-col" lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={1}
          description="新会员促进首购"
        />
      </Col>
      <Col className="icon-text-col" lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={20}
          description="会员个性化场景营销"
        />
      </Col>
      <Col className="icon-text-col" lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={21}
          description="会员生命周期自动化维护"
        />
      </Col>
      <Col className="icon-text-col" lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={22}
          description="全渠道会员增长"
        />
      </Col>
      <Col className="icon-text-col" lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={9}
          description="会员画像体系"
        />
      </Col>
      <Col className="icon-text-col" lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={23}
          description="会员标签体系"
        />
      </Col>
      <Col className="icon-text-col" lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={24}
          description="会员积分管理和运营"
        />
      </Col>
      <Col className="icon-text-col" lg={6} md={8} xs={12}>
        <VerticalBox
          iconIndex={6}
          description="会员权益体系"
        />
      </Col>
    </TitledVerticalBoxes>
    <FmcgContentList />
    <CustomerCase
      title="快消行业客户案例"
      icon={Budweiser}
      paddingTop={0}
      description="百威英博成立于1876年，是全球领先的啤酒酿造商，也是全球五大消费品公司之一。作为一家以消费者为中心、以销售为推动力的大型公司，百威英博旗下经营着超过200多个品牌，其中包括百威、哈啤、科罗娜、时代等著名啤酒品牌。"
      challenges={[
        {
          id: 'icon-1',
          iconIndex: 25,
          className: 'thin square',
          description: '传统渠道布局成熟但发展遇到瓶颈，多年呈现低速增长，渠道层级多且无价格优势。',
        },
        {
          id: 'icon-2',
          iconIndex: 26,
          className: 'square',
          description: '流量成本居高不下，广告投放成本逐年升高，转化率低。',
        },
        {
          id: 'icon-3',
          iconIndex: 27,
          className: 'square',
          description: '多种渠道分散，难以有效沉淀用户。',
        },
        {
          id: 'icon-4',
          iconIndex: 20,
          className: 'square',
          description: '随着消费者需求的个性化，消费行为碎片化，大众逐渐缺乏品牌认知，忠诚度低。',
        },
      ]}
      solutionsNode={<FmcgSolution />}
      effects={[
        {
          id: 'icon-1',
          iconId: 'iconeffect-1',
          content: '数据资产的回流收集，将客户资产从渠道方收回品牌方，与消费者直接产生关联。',
        },
        {
          id: 'icon-2',
          iconId: 'iconeffect-2',
          content: '全触点消费者身份识别，形成全域档案搭建全渠道会员体系，并识别用户生命周期，建立消费者个性化1对1沟通的能力。',
        },
        {
          id: 'icon-3',
          iconId: 'iconeffect-3',
          content: '搭建全触点和交互体系，累积近千万有互动记录的高质量消费者档案记录。',
        },
        {
          id: 'icon-4',
          iconId: 'iconeffect-4',
          content: '通过持续的个性化互动提高了消费者活跃度和黏性，微信新增粉丝平均活跃度提高了4倍，带动官方商城月订单数增加了超过3倍。',
        },
      ]}
    />
  </Layout>
);
