import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import Dm1 from '../images/dm-1.svg';

export default () => {
  return (
    <div className="content-list-wrapper">
      <section className="image-text-section">
        <Container>
          <ImageText image={Dm1} imgWidth={413} imgHeight={347} title="AI Hub赋能快消行业智能化营销">
            <ImageTextDescription label="AI智能营销圈群和聚类分析，有效提升CVR和CTR" />
            <ImageTextDescription label="NBA（Next Best Action）智能营销沟通策略有效提升RFM" />
            <ImageTextDescription label="智能推荐系统，为多场景提供精准的商品推荐和人群匹配" />
            <ImageTextDescription label="智能化广告投放管理，实时人群决策引擎提升广告ROI" />
          </ImageText>
        </Container>
      </section>
    </div>
  );
};
