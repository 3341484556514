import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Col, Row } from 'antd';
import Container from './Container';
import BlockIconText from './BlockIconText';

export default () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);
  return (
    <div className="content-list-wrapper">
      <div>
        <section className="image-text-section fmcg-solution">
          <Container>
            <h3>解决方案</h3>
            <Row className="image-text">
              <Col
                lg={{ span: 14, order: 1 }}
                xs={{ span: 24, order: 2 }}
                className="content"
              >
                <Fade
                  left={!isMobile}
                  bottom={isMobile}
                  duration={1000}
                  delay={200}
                  distance="30px"
                >
                  <>
                    <div className="content-wrapper">
                      <p className="content-title">百威全渠道消费者互动（CEM）项目</p>
                      <BlockIconText content="建立全渠道会员中心体系，整合打通线上和线下数据，提升用户品牌粘度和复购率" color="blue" />
                      <BlockIconText content="结合百威各季度品牌活动及投放渠道互动过程，收集用户标签，形成完整的用户画像，并据此优化用户策略" color="green" />
                      <BlockIconText content="使用DM Hub整合各种营销渠道，对客户全触点进行consumer 1:1 营销策略和营销自动流搭建" color="blue" />
                    </div>
                    <div className="content-wrapper">
                      <p className="content-title">百威2B零售终端运营项目</p>
                      <BlockIconText content="基于零售终端店生命周期管理的个性化自动流程设计，适用于激活、唤醒、复购和推广阶段" color="blue" />
                      <BlockIconText content="整合各种营销工具对客户进行全自动基础营销" color="green" />
                      <BlockIconText content="通过不同流程的搭建实现了智能化、策略化的客户场景处理" color="blue" />
                      <BlockIconText content="通过客户的不同反应收集用户标签，形成完整的用户画像，并再次优化用户策略" color="green" />
                    </div>
                  </>
                </Fade>
              </Col>
              <Col
                lg={{ span: 10, order: 2 }}
                xs={{ span: 24, order: 1 }}
                className="image"
              >
                <Fade
                  right={!isMobile}
                  bottom={isMobile}
                  duration={600}
                  delay={200}
                  distance="30px"
                >
                  <div className="fmcg-solution-image" />
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};
